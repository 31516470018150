import moldura from './moldura.png'
import './App.css';
import { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

function App() {
  const [imagem, setImagem] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState({ x: 0, y: 0, width: 0, height: 0 });

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels)
  }, [])

  const download = async () => {
    const image = await createImage(imagem)
    const molduraImg = await createImage(moldura)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx)
      return null;

    const dim = Math.min(image.width, image.height)

    canvas.width = dim / zoom;
    canvas.height = dim / zoom;


    ctx.drawImage(
      image,
      croppedArea.x, croppedArea.y, croppedArea.width, croppedArea.height,
      0, 0, canvas.width, canvas.height,
    )

    ctx.drawImage(
      molduraImg,
      0, 0, canvas.width, canvas.height,
    )

    canvas.toBlob((file) => {
      const link = document.createElement('a')
      link.href = URL.createObjectURL(file)
      link.download = 'moldura.jpg'

      link.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      }))

      setTimeout(() => {
        URL.revokeObjectURL(link.href)
        link.remove()
      })
    }, 'image/jpeg')
  }

  return (
    <div className="flex flex-col gap-2 mx-auto container text-center items-center">
      <h1 className='text-3xl'>Molduras Salsichão 🌭</h1>

      <div className='flex flex-col w-min'>
        <label htmlFor='imagem' className='text-2xl'>Escolhe a tua imagem...</label>
        <input type='file' accept='image/*' name='imagem' onChange={(e) => {
          const fileList = e.target.files;
          if (fileList) {
            const file = fileList[0];
            setImagem(URL.createObjectURL(file));
          }
        }} />
      </div>

      {imagem && (
        <>
          <div className='w-full relative aspect-square'>
            <Cropper
              image={imagem}
              crop={crop}
              zoom={zoom}
              aspect={1}
              restrictPosition={false}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              cropShape='round'
              objectFit='cover'
              // classes={{ containerClassName: 'cropper' }}
              style={{
                cropAreaStyle: {
                  backgroundImage: `url(${moldura})`,
                  backgroundSize: 'cover',
                }
              }}
            />
          </div>
          <button className='bg-yellow-400 px-4 py-2 text-2xl rounded-md shadow-md hover:bg-yellow-300 hover:shadow-xl transition-all duration-100' onClick={download}>Descarregar</button>
        </>
      )}
    </div>
  );
}

export default App;
